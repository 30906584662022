<template>
  <div class="home">
    <el-col>
      <el-menu :default-active="indexRouterData" class="el-menu-vertical-demo" background-color="#222e48"
        text-color="#ebf0f5" active-text-color="#4f7dff" @open="handleOpen" @close="handleClose">
        <!-- @open="handleOpen" @close="handleClose" -->
        <div class="login">
          <img :src="logo" alt="logo" />
        </div>
        <el-menu-item index="1" @click="navTo('/index'); menuIndex('1')">
          <!-- <i class="el-icon-setting"></i> -->
          <img v-if="menuID !== '1'" class="menu-item-ui" src="../assets/home/index.png" alt="" />
          <img v-if="menuID === '1'" class="menu-item-ui" src="../assets/home/index2.png" alt="">
          <span slot="title" to="/">
            <!-- <router-link to="/Home">数据总览</router-link> -->
            数据总览
          </span>
        </el-menu-item>
        <el-menu-item index="2" @click="navTo('/invoice'); menuIndex('2')">
          <img v-if="menuID !== '2'" class="menu-item-ui" src="../assets/home/invoice.png" alt="" />
          <img v-if="menuID === '2'" class="menu-item-ui" src="../assets/home/invoice2.png" alt="">
          <span slot="title">发票及账单</span>
        </el-menu-item>
        <el-menu-item index="3" @click="navTo('/contract'); menuIndex('3')">
          <!-- <img class="menu-item-ui" src="../assets/home/contract.png" alt="" /> -->
          <img v-if="menuID !== '3'" class="menu-item-ui" src="../assets/home/contract.png" alt="" />
          <img v-if="menuID === '3'" class="menu-item-ui" src="../assets/home/contract2.png" alt="">
          <span slot="title">签约合同</span>
        </el-menu-item>

        <el-submenu index="4" @click="menuIndex('4')">
          <template slot="title">
            <img class="menu-item-ui" src="../assets/home/upload.png" alt="" />
            <span>下载报表</span>
          </template>
          <el-menu-item-group>
            <el-menu-item v-for="(item) in list" :key="item.id"
              @click="navTo(`/upload?styleID=${item.id}`); menuIndex('4')">{{ item.name }}
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>

        <el-menu-item index="5" @click="navTo('/news'); menuIndex('5')">
          <img v-if="menuID !== '5'" class="menu-item-ui" src="../assets/home/news.png" alt="" />
          <img v-if="menuID === '5'" class="menu-item-ui" src="../assets/home/news2.png" alt="">
          <span slot="title">消息中心</span>
        </el-menu-item>
        <el-menu-item index="6" @click="navTo('/customer'); menuIndex('6')">
          <img v-if="menuID !== '6'" class="menu-item-ui" src="../assets/home/customer.png" alt="" />
          <img v-if="menuID === '6'" class="menu-item-ui" src="../assets/home/customer2.png" alt="">
          <span slot="title">联系客服</span>
        </el-menu-item>
        <el-menu-item index="7" @click="navTo('/my'); menuIndex('7')">
          <img v-if="menuID !== '7'" class="menu-item-ui" src="../assets/home/my.png" alt="" />
          <img v-if="menuID === '7'" class="menu-item-ui" src="../assets/home/my2.png" alt="">
          <span slot="title">个人设置</span>
        </el-menu-item>
      </el-menu>
    </el-col>
    <!-- 右侧 -->
    <div class="right-box">

      <router-view></router-view>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      logo:'',
      list: [{
        id: 1,
        title: 'QQ音乐',
        index: "4-1"

      }, {
        id: 2,
        title: '网易云音乐',
        index: "4-2"
      }, {
        id: 3,
        title: '快手',
        index: '4-3'
      }],
      menuID: '1',//侧导航栏Index
      indexRouterData: '1',
      uploadData: '',
    }
  },
  created() {

    this.indexRouter()
    this.PostUser()
    this.getLogo();
  },
  methods: {
    getLogo() {
            this.$api.config().then(res=>{
                this.logo = res.logo2
            })
        },
    indexRouter() {
      let path = this.$route.path
      let id = this.$route.query.styleID
      if (path == '/index') {
        this.indexRouterData = '1'
        this.menuID = '1'
        return
      } else if (path == '/invoice') {
        this.indexRouterData = '2'
        this.menuID = '2'
        return
      } else if (path == '/contract') {
        this.indexRouterData = '3'
        this.menuID = '3'
        return
      }
      // else if(path == '/upload' && id == 1){
      //   this.indexRouterData = '4-1'
      //   this.menuID = null
      //   // this.$store.commit('STYLENAV',1)
      //   return
      // }else if(path == '/upload' && id == 2){
      //   this.indexRouterData = '4-2'
      //   this.menuID = null
      //   // this.$store.commit('STYLENAV',2)
      //   return
      // }else if(path == '/upload' && id == 3){
      //   this.indexRouterData = '4-3'
      //   this.menuID = null
      //   // this.$store.commit('STYLENAV',3)
      //   return
      // }
      else if (path == '/news') {
        this.indexRouterData = '5'
        this.menuID = '5'
        return
      } else if (path == '/customer') {
        this.indexRouterData = '6'
        this.menuID = '6'
        return
      } else if (path == '/my') {
        this.indexRouterData = '7'
        this.menuID = '7'
        return
      }
    },
    menuIndex(index) {
      this.menuID = index
    },
    handleOpen(key, keyPath) {
      console.log(1);
      // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(2);
      // console.log(key, keyPath);
    },
    PostUser() {
      let userID = localStorage.getItem('userID');
      this.$api.user({
        userId: userID
      }).then(res => {
        this.uploadData = res.plate
        console.log(this.uploadData);
        // localStorage.setItem('upload', JSON.stringify(res.plate))
        this.listIndex()
      })
    },
    listIndex() {
      let list = this.uploadData
      list.forEach((item, index) => {
        item.index = '4-' + (index + 1)
      });
      console.log(list, 'list');
      this.list = list
    },
  },

}
</script>


<style scoped>
.menu-item-ui {
  width: 1em;
  height: 1em;
  margin-right: 0.8em;
}

.home {
  background-color: #fff;
}

.el-col {
  width: 12em;
  top: 0;
  left: 0;
  position: fixed;
}

.login {
  text-align: center;
  margin: 0.8em 0;
}

.login img {
  width: 8em;
  height: 2em;
}

.el-menu {
  height: 100vh;
}

.el-menu-item {
  /* text-align: center; */
}

.el-submenu .el-menu-item {
  min-width: auto;
}

.right-box {
  margin-left: 12em;
  min-height: 100vh;
  /* background-color: #eee; */
}

a {
  color: #e5ecf4;
}
</style>